import { Badge as ChakraBadge, Flex, Text } from '@chakra-ui/react'
import { Contract } from 'models/contract'
import { DiapersOrder } from 'models/diapersOrder'
import { Order } from 'models/order'
import { ReactNode } from 'react'
import { Patient, PatientMedicine } from 'models/patient'
import { Medicine } from 'models/dictionaries/medicine'
import { Task } from 'models/task'
import { WarehouseDocument } from 'models/warehouseDocument'
import { Announcement } from 'models/announcement'
import { Bill } from 'models/bill'
import { RehabilitationCard } from 'models/rehabilitaionCard'
import { RehabilitationSession } from 'models/rehabilitationSession'

export type StatusBadgeProps = {
	patient?: Patient
	diaperOrder?: DiapersOrder
	contract?: Contract
	order?: Order
	patientMedicine?: PatientMedicine
	warehouseDocument?: WarehouseDocument
	announcement?: Announcement
	bill?: Bill
	rehabilitationCard?: RehabilitationCard
	rehabilitationSession?: RehabilitationSession
}

export const StatusBadge = (props: StatusBadgeProps) => {
	return (
		<Flex alignItems="center" gap={4}>
			<Heading {...props} />
			<Badge {...props} />
		</Flex>
	)
}

const Heading = (props: StatusBadgeProps): JSX.Element => {
	if (props.patient) {
		return (
			<Text>
				{props.patient.firstName} {props.patient.lastName}
			</Text>
		)
	}
	if (props.diaperOrder) {
		return <Text>{props.diaperOrder.number}</Text>
	}
	if (props.contract) {
		return <Text>{props.contract.number}</Text>
	}
	if (props.order) {
		return <Text>{props.order.number}</Text>
	}
	if (props.warehouseDocument) {
		return <Text>{props.warehouseDocument.number}</Text>
	}
	if (props.bill) {
		return <Text>{props.bill.number}</Text>
	}
	if (props.rehabilitationCard) {
		return <Text>{props.rehabilitationCard.cycle}</Text>
	}
	if (props.rehabilitationSession) {
		return <Text>{props.rehabilitationSession.sessionNumber}</Text>
	}
	return <></>
}
export const Badge = (
	props: StatusBadgeProps & { task?: Task },
): JSX.Element => {
	let colorScheme = ''
	let statusText = ''
	if (props.patient) {
		colorScheme = Patient.getStatusColor(props.patient.status)
		statusText = Patient.convertStatus(props.patient.status)
	}
	if (props.diaperOrder) {
		colorScheme = DiapersOrder.getStatusColor(props.diaperOrder.status)
		statusText = DiapersOrder.convertStatus(props.diaperOrder.status)
	}
	if (props.contract) {
		colorScheme = Contract.getStatusColor(props.contract.status)
		statusText = Contract.convertStatus(props.contract.status)
	}
	if (props.order) {
		colorScheme = Order.getStatusColor(props.order.status)
		statusText = Order.convertStatus(props.order.status)
	}
	if (props.patientMedicine) {
		colorScheme = Medicine.getStatusColor(props.patientMedicine.status)
		statusText = Medicine.convertStatus(props.patientMedicine.status)
	}
	if (props.task) {
		colorScheme = Task.getStatusColor(props.task.status)
		statusText = Task.convertStatus(props.task.status)
	}
	if (props.warehouseDocument) {
		colorScheme = WarehouseDocument.getStatusColor(
			props.warehouseDocument.status,
		)
		statusText = WarehouseDocument.convertStatus(props.warehouseDocument.status)
	}

	if (props.announcement) {
		colorScheme = Announcement.getStatusColor(props.announcement.status)
		statusText = Announcement.convertStatus(props.announcement.status)
	}
	if (props.bill) {
		colorScheme = Bill.getStatusColor(props.bill.status)
		statusText = Bill.convertStatus(props.bill.status)
	}
	if (props.rehabilitationCard) {
		colorScheme = RehabilitationCard.getStatusColor(props.rehabilitationCard.status)
		statusText = RehabilitationCard.convertStatus(props.rehabilitationCard.status)
	}
	if (props.rehabilitationSession) {
		colorScheme = RehabilitationSession.getStatusColor(props.rehabilitationSession.status)
		statusText = RehabilitationSession.convertStatus(props.rehabilitationSession.status)
	}

	return (
		<ChakraBadge
			display="flex"
			alignItems="center"
			width="fit-content"
			h={6}
			mt={1}
			w="fit-content"
			colorScheme={colorScheme}
		>
			{statusText}
		</ChakraBadge>
	)
}
