import { RouteObject } from 'react-router-dom'
import { RehabilitaionCard } from './RehabilitationCard'
import { RehabilitationCardList } from './RehabilitationCardList'
import { RehabilitationSessionList } from './RehabilitationSessionList'
import { General } from './views/General'
import { GeneralEdit } from './views/GeneralEdit'

const patientRehabilitationRoutes: RouteObject[] = [
	{
		path: '/patients/:id/rehabilitations',
		element: (
			<RehabilitationSessionList />
		),
		breadcrumb: 'Plan rehabilitacji',
	},
	{
		path: '/patients/:id/rehabilitations/cards',
		element: (
			<RehabilitationCardList />
		),
		breadcrumb: 'Karty',
	},
	{
		path: '/patients/:id/rehabilitations/cards/:cardId',
		element: <RehabilitaionCard />,
		children: [
			{
				index: true,
				element: <General />,
				breadcrumb: 'Karta rehabilitacji',
			},
			{
				path: '/patients/:id/rehabilitations/cards/:cardId/edit',
				element: <GeneralEdit />,
				breadcrumb: 'Edycja karty rehabilitacji',
			}
		]
	},
	{
		path: '/patients/:id/rehabilitations/cards/new',
		element: <RehabilitaionCard />,
		children: [
			{
				index: true,
				element: <GeneralEdit />,
				breadcrumb: 'Nowa karta rehabilitacji',
			}
		]
	}
	// {
	// 	path: '/patients/:id/bills/:billId',
	// 	element: (
	// 			<Bill />
	// 	),
	// 	children: [
	// 		{
	// 			index: true,
	// 			element: <General />,
	// 			breadcrumb: 'Rachunek',
	// 			breadcrumbIcon: <FaFileInvoiceDollar />,
	// 		},
	// 		{
	// 			path: 'edit',
	// 			element: <GeneralEdit />,
	// 			breadcrumb: 'Edycja rachunku',
	// 			breadcrumbIcon: <FaFileInvoiceDollar />,
	// 		},
	// 		{
	// 			path: 'attachments',
	// 			element: <AttachmentsWrapper />,
	// 			breadcrumb: 'Lista załączników',
	// 			breadcrumbIcon: <FaFileInvoiceDollar />,
	// 		},
	// 	],
	// },
	// {
	// 	path: '/patients/:id/bills/new',
	// 	element: (
	// 		<ModuleRoute type="CONTRACTS">
	// 			<Bill />
	// 		</ModuleRoute>
	// 	),
	// 	children: [
	// 		{
	// 			index: true,
	// 			element: <GeneralEdit />,
	// 			breadcrumb: 'Nowy rachunek',
	// 			breadcrumbIcon: <FaFileInvoiceDollar />,
	// 		},
	// 	],
	// },
]

export default patientRehabilitationRoutes
