import { Box, GridItem, Textarea } from "@chakra-ui/react"
import { DataDisplay, DataDisplayRow, formatDate, Label, Tile, TileButton, TileEditButton } from "@kevea/react-components"
import { StatusBadge } from "components/StatusBadge"
import { TRehabilitationCard } from "models/rehabilitaionCard"
import { useMemo, useState } from "react"
import { useOutletContext } from "react-router"
import { RehabilitationCardService } from "services/rehabilitationCard"
import { RehabilitaionCardContext } from "../RehabilitationCard"

export const General = () => {
	const { card, refetch, ...rest } = useOutletContext<RehabilitaionCardContext>()
	const [loading, setLoading] = useState(false)



	const confirm = () => {
		setLoading(true)
		RehabilitationCardService.put({ ...card, status: 2 }, card.patient).then(() => {
			refetch()
			setLoading(false)
		})
	}

	const rows = useMemo<DataDisplayRow<TRehabilitationCard>[]>(
		() => [
			{
				accessor: 'referralNumber',
				label: 'Numer skierowania',
			},
			{
				accessor: 'cycle',
				label: 'Cykl',
			},
			{
				accessor: 'numberOfSessions',
				label: 'Liczba sesji',
			},
			{
				accessor: 'startDate',
				label: 'Data rozpoczęcia',
				render: v => formatDate(new Date(v).toISOString(), 'localeDateString'),
			},
			{
				accessor: 'recurrence',
				label: 'Powtarzalność',
			},
			{
				accessor: 'sessionTime',
				label: 'Czas sesji',
			},
			{
				accessor: 'metalImplant',
				label: 'Metalowy implant',
				type: 'yes/no',
			},
			{
				accessor: 'inflammation',
				label: 'Stan zapalny',
				type: 'yes/no',
			},
			{
				accessor: 'cancer',
				label: 'Nowotwory',
				type: 'yes/no',
			},
			{
				accessor: 'fever',
				label: 'Gorączka',
				type: 'yes/no',
			},
			{
				accessor: 'sensoryDisorders',
				label: 'Zaburzenia czucia',
				type: 'yes/no',
			},
			{
				accessor: 'pacemaker',
				label: 'Rozrusznik',
				type: 'yes/no',
			},
			{
				accessor: 'epilepsy',
				label: 'Padaczka',
				type: 'yes/no',
			},
			{
				accessor: 'heartDisease',
				label: 'Choroby serca',
				type: 'yes/no',
			},
			{
				accessor: 'hypertension',
				label: 'Nadciśnienie tętnicze',
				type: 'yes/no',
			},
			{
				accessor: 'venousProblems',
				label: 'Problemy żylne',
				type: 'yes/no',
			},
			{
				accessor: 'diabetes',
				label: 'Cukrzyca',
				type: 'yes/no',
			}
		],
		[],
	)

	return <Tile
		isLoading={loading}
		grid
		{...rest}
		titleElement={<StatusBadge rehabilitationCard={card} />}
		headerButtons={
			<>
				{card.status === 1 && <TileButton onClick={confirm} colorScheme={'green'}>Potwierdź</TileButton>}
				{card.status === 1 && <TileEditButton />}
			</>
		}
	>
		<GridItem colSpan={{ base: 2, lg: 1 }}>
			<DataDisplay data={card} rows={rows} />
		</GridItem>

		<GridItem colSpan={{ base: 2, lg: 1 }}>
			<Label>Główne dolegliwości</Label>
			<Textarea readOnly resize='none' height={125} value={card.mainAilments} />



			<Label>Rozpoznanie</Label>
			<Textarea readOnly resize='none' height={125} value={card.diagnosis} />


			<Label>Uwagi</Label>
			<Textarea readOnly resize='none' height={125} value={card.comments} />


			<Label>Inne choroby</Label>
			<Textarea readOnly resize='none' height={125} value={card.otherDiseases} />


			<Label>Opinia lekarza o przeciwskazaniach do rehabilitacj</Label>
			<Textarea readOnly resize='none' height={125} value={card.medicalContraindications} />

		</GridItem>
	</Tile>
}