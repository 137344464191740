import { AxiosResponse } from "axios"
import { RehabilitationCard } from "models/rehabilitaionCard"
import { RehabilitationSession } from "models/rehabilitationSession"
import { resolvePath, Service } from "services"
import http from "./axios"

const path = '/rehabilitationSessions'
const objectPath = '/patients'

export class RehabilitationSessionsService extends Service<RehabilitationSession>({ path, objectPath, objectService: true }) {
}