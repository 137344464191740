import {
	OutletContext,
	TileGrid,
	useBreadcrumbs,
	useNotFoundToast,
} from '@kevea/react-components'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import {
	Outlet,
	useNavigate,
	useOutletContext,
	useParams,
	useSearchParams,
} from 'react-router-dom'
import { StatusBadge } from 'components/StatusBadge'
import { PatientService } from 'services/patient'
import patientBillRoutes from './patientRoutes'
import { FaFileInvoiceDollar } from 'react-icons/fa'
import patientRoutes from 'pages/patients/routes'
import { Patient } from 'models/patient'
import { RehabilitationCard as RehabilitationCardModel } from 'models/rehabilitaionCard'
import { BillService } from 'services/bill'
import { RehabilitationCardService } from 'services/rehabilitationCard'
import patientRehabilitationRoutes from './patientRoutes'
import { isModuleEnabled } from 'routes'

export type RehabilitaionCardContext = OutletContext & {
	card: RehabilitationCardModel | undefined
}

export const RehabilitaionCard = () => {
	const { id: patientId } = useParams<{ id: string }>()
	const { cardId } = useParams<{
		cardId: string
	}>()
	let [searchParams, setSearchParams] = useSearchParams()

	useEffect(() => {
		if (!isModuleEnabled('REHABILITATIONS')) window.location.href = '/'
	}, [])

	const toast = useNotFoundToast()
	const navigate = useNavigate()

	const fetchCard = async (): Promise<RehabilitationCardModel> => {
		if (cardId && cardId !== 'new') {
			try {
				let res
				if (patientId) {
					res = await RehabilitationCardService.get(cardId, patientId)
				} else {
					// res = await BillService.get(billId)
				}
				return res.data
			} catch (e) {
				toast()
				if (patientId) {
					navigate(`/patients/${patientId}/rehabilitations/cards`)
				} else {
					navigate(`/rehabilitations/cards`)
				}
				return undefined
			}
		} else {
			return new RehabilitationCardModel()
		}
	}
	const {
		data: card,
		isLoading,
		isRefetching,
		refetch,
	} = useQuery('fetchRehabilitationCard', fetchCard)

	const breadcrumbs = useBreadcrumbs(
		[...patientRoutes, ...patientRehabilitationRoutes]
		// patientId
		// 	? [...patientRoutes, ...patientRehabilitationRoutes]
		// 	: [
		// 			{
		// 				path: 'bills',
		// 				breadcrumb: 'Rozrachunki',
		// 			},
		// 			{
		// 				path: 'bills/:billId',
		// 				element: <Bill />,
		// 				children: [
		// 					{
		// 						index: true,
		// 						breadcrumb: 'Rachunek',
		// 						breadcrumbIcon: <FaFileInvoiceDollar />,
		// 					},
		// 					{
		// 						path: 'edit',
		// 						breadcrumb: 'Edycja rachunku',
		// 						breadcrumbIcon: <FaFileInvoiceDollar />,
		// 					},
		// 					{
		// 						path: 'attachments',
		// 						breadcrumb: 'Lista załączników',
		// 						breadcrumbIcon: <FaFileInvoiceDollar />,
		// 					},
		// 				],
		// 			},
		// 			{
		// 				path: 'bills/new',
		// 				element: <Bill />,
		// 				children: [
		// 					{
		// 						index: true,
		// 						breadcrumb: 'Nowy rachunek',
		// 						breadcrumbIcon: <FaFileInvoiceDollar />,
		// 					},
		// 				],
		// 			},
		// 	  ],
	)
	const context: RehabilitaionCardContext = {
		breadcrumbs: breadcrumbs,
		card,
		titleElement: "Karta rehabilitacji",
		refetch,
	}

	useEffect(() => {
		refetch()
	}, [cardId])

	return (
		<TileGrid isLoading={isLoading || isRefetching}>
			<Outlet context={context} />
		</TileGrid>
	)
}
