import { formatDate } from "@kevea/react-components"

export class RehabilitationSession {

	constructor(session?: RehabilitationSession) {
		this._id = session?._id
		this.rehabilitationCard = session?.rehabilitationCard
		this.patient = session?.patient
		this.sessionNumber = session?.sessionNumber ?? 0
		this.sessionTime = session?.sessionTime ?? 0
		this.date = session?.date ?? new Date()
		this.activities = session?.activities ?? []
		this.note = session?.note ?? ''
		this.status = session?.status ?? 1
	}

	_id?: string
	rehabilitationCard: {
		_id: string
		cycle: string
		numberOfSessions: number
	}
	patient: string
	sessionNumber: number
	sessionTime: number
	date: Date
	activities: string[]
	note: string
	status: number //1 -do wykonania, 2 - wykonano, 3 - anulowano



	static convertStatus = (status: number): string => {
		switch (status) {
			case 1:
				return 'Do wykonania'
			case 2:
				return 'Wykonano'
			case 3:
				return 'Anulowano'
			default:
				return `${status}`
		}
	}

	static getStatusColor = (status: number): string => {
		switch (status) {
			case 1:
				return 'future'
			case 2:
				return 'active'
			case 3:
				return 'dead'
			default:
				return `${status}`
		}
	}
}

export class TableRehabilitationSession {
	constructor(session: RehabilitationSession) {
		this.cycle = session.rehabilitationCard.cycle
		this.numberOfSessions = session.rehabilitationCard.numberOfSessions
		this.patient = session.patient
		this.sessionNumber = session.sessionNumber
		this.sessionTime = session.sessionTime
		this.date = formatDate(new Date(session.date).toISOString(), 'localeDateString')
		this.dateForSorting = new Date(session.date)
		this.activities = session.activities
		this.note = session.note
		this.status = session.status
		this.original = session
		if (session.status === 2) {
			this.complitionTime = formatDate(new Date(session.date).toISOString(), 'time')
		} else {
			this.complitionTime = '-'
		}
	}
	cycle: string
	numberOfSessions: number
	patient: string
	sessionNumber: number
	sessionTime: number
	dateForSorting: Date
	complitionTime: string
	date: string
	activities: string[]
	note: string
	status: number //1 -do wykonania, 2 - wykonano, 3 - anulowano
	original: RehabilitationSession
}