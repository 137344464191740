import { formatDate } from "@kevea/react-components"

export type TRehabilitationCard = {
	_id?: string
	patient: string
	referralNumber: string
	startDate: string
	cycle: string
	numberOfSessions: number
	recurrence: number // co ile dni
	sessionTime: number //to jest czas jednej sesji godzina zawsze zmienna
	mainAilments: string //
	comorbidities: string
	metalImplant: boolean
	pacemaker: boolean
	epilepsy: boolean
	inflammation: boolean
	heartDisease: boolean
	cancer: boolean
	hypertension: boolean
	fever: boolean
	venousProblems: boolean
	sensoryDisorders: boolean
	diabetes: boolean
	venousProblemsNote: string
	otherDiseases: string
	diagnosis: string
	qualification: string
	comments: string
	medicalContraindications: string
	status: number // 1 - niepotwierdzona, 2 - potwierdzona
}

export class RehabilitationCard {

	constructor(card?: RehabilitationCard) {
		this._id = card?._id
		this.patient = card?.patient
		this.referralNumber = card?.referralNumber ?? ''
		this.startDate = card?.startDate ? formatDate(new Date(card.startDate).toISOString(), 'date') : formatDate(new Date().toISOString(), 'date')
		this.cycle = card?.cycle ?? ''
		this.numberOfSessions = card?.numberOfSessions ?? 10
		this.recurrence = card?.recurrence ?? 1
		this.sessionTime = card?.sessionTime ?? 90
		this.mainAilments = card?.mainAilments ?? ''
		this.comorbidities = card?.comorbidities ?? ''
		this.metalImplant = card?.metalImplant ?? false
		this.pacemaker = card?.pacemaker ?? false
		this.epilepsy = card?.epilepsy ?? false
		this.inflammation = card?.inflammation ?? false
		this.heartDisease = card?.heartDisease ?? false
		this.cancer = card?.cancer ?? false
		this.hypertension = card?.hypertension ?? false
		this.fever = card?.fever ?? false
		this.venousProblems = card?.venousProblems ?? false
		this.sensoryDisorders = card?.sensoryDisorders ?? false
		this.diabetes = card?.diabetes ?? false
		this.venousProblemsNote = card?.venousProblemsNote ?? ''
		this.otherDiseases = card?.otherDiseases ?? ''
		this.diagnosis = card?.diagnosis ?? ''
		this.qualification = card?.qualification ?? ''
		this.comments = card?.comments ?? ''
		this.medicalContraindications = card?.medicalContraindications ?? ''
		this.status = card?.status ?? 1
	}

	_id?: string
	patient: string
	referralNumber: string
	startDate: string
	cycle: string
	numberOfSessions: number
	recurrence: number // co ile dni
	sessionTime: number //to jest czas jednej sesji godzina zawsze zmienna
	mainAilments: string //
	comorbidities: string
	metalImplant: boolean
	pacemaker: boolean
	epilepsy: boolean
	inflammation: boolean
	heartDisease: boolean
	cancer: boolean
	hypertension: boolean
	fever: boolean
	venousProblems: boolean
	sensoryDisorders: boolean
	diabetes: boolean
	venousProblemsNote: string
	otherDiseases: string
	diagnosis: string
	qualification: string
	comments: string
	medicalContraindications: string
	status: number // 1 - niepotwierdzona, 2 - potwierdzona


	static convertStatus = (status: number): string => {
		switch (status) {
			case 1:
				return 'Niepotwierdzona'
			case 2:
				return 'Potwierdzona'
			default:
				return `${status}`
		}
	}

	static getStatusColor = (status: number): string => {
		switch (status) {
			case 1:
				return 'future'
			case 2:
				return 'active'
			default:
				return `${status}`
		}
	}
}

export class TableRehabilitationCard {

	constructor(card: RehabilitationCard) {
		this._id = card._id
		this.cycle = card.cycle
		this.numberOfSessions = card.numberOfSessions
		this.status = card.status
		this.startDate = formatDate(new Date(card.startDate).toISOString(), 'localeDateString')
		this.startDateForSorting = new Date(card.startDate)
		this.original = card

		const combinedTime = +card.sessionTime * +card.numberOfSessions
		const hours = Math.floor(combinedTime / 60)
		const minutes = combinedTime % 60
		this.combinedSessionTime = `${hours.toFixed(0)}h`
		if (minutes > 0) {
			this.combinedSessionTime += ` ${minutes.toFixed(0)}min`
		}
	}

	_id?: string
	cycle: string
	numberOfSessions: number
	combinedSessionTime: string
	status: number
	startDate: string
	startDateForSorting: Date
	original: RehabilitationCard
}