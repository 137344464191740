import { Box, FormControl, FormErrorMessage, Input, InputGroup, InputRightAddon, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react"
import { formatDate, InputField, Label, ModalDialog, TextAreaField } from "@kevea/react-components"
import { Form, Formik, FormikProps, useField, useFormikContext } from "formik"
import { padStart } from "lodash"
import { RehabilitationSession } from "models/rehabilitationSession"
import { useEffect, useMemo, useRef, useState } from "react"
import { FaPlus } from "react-icons/fa"
import * as yup from 'yup'

type Props = {
	isOpen: boolean
	onClose: (session?: RehabilitationSession) => void
	session: RehabilitationSession
}

type FormValues = {
	comment: string
	timeOfCompletion: string
	dateOfCompletion: string
	activities: string[]
}

const formValuesSchema = yup.object({
	'timeOfCompletion': yup.string().required('Godzina wykonania jest wymagana'),
	'dateOfCompletion': yup.string().required('Data wykonania jest wymagana'),
	'activities': yup.array(yup.string()).min(1, 'Dodaj przynajmniej jedną wykonaną czynność')
})

export const CompleteSessionModal = ({ isOpen, onClose, session }: Props) => {

	const formik = useRef<FormikProps<FormValues>>(null)

	const handleSubmit = (values: FormValues) => {
		const completionDate = new Date(values.dateOfCompletion)
		const [hours, minutes] = values.timeOfCompletion.split(':')
		completionDate.setHours(+hours, +minutes, 0, 0)
		onClose({ ...session, date: completionDate, activities: values.activities, note: values.comment, status: 2 })
	}

	const getCurrentTime = () => {
		const date = new Date()
		const hours = date.getHours().toString()
		const minutes = date.getMinutes().toString()
		return `${padStart(hours, 2)}:${padStart(minutes, 2)}`
	}

	return <ModalDialog header={session.status === 1 ? 'Wykonaj rehabilitację' : 'Podgląd rehabilitacji'}
		isOpen={isOpen}
		onClose={() => onClose(undefined)}
		onSave={() => formik.current?.submitForm()}>
		<Label>Cykl</Label>
		<Input value={session.rehabilitationCard.cycle} readOnly disabled />
		<Label>Sesja</Label>
		<Input value={session.sessionNumber} readOnly disabled />
		<Label>Czas sesji</Label>
		<InputGroup>
			<Input value={session.sessionTime} readOnly disabled />
			<InputRightAddon>min</InputRightAddon>
		</InputGroup>
		<Formik onSubmit={handleSubmit} initialValues={{ activities: [], comment: '', dateOfCompletion: formatDate(new Date(session.date).toISOString(), 'date'), timeOfCompletion: getCurrentTime() }} innerRef={formik} validationSchema={formValuesSchema}>
			<Form>
				<InputField name="dateOfCompletion" type='date' label="Data wykonania" />
				<InputField name="timeOfCompletion" type='time' label="Godzina wykonania" />
				<ActivityPicker name="activities" />
				<TextAreaField name="comment" label="Notatka"></TextAreaField>
			</Form>
		</Formik>
	</ModalDialog>
}

type ActivityPickerProps = {
	name: string
}

const ActivityPicker = ({ name }: ActivityPickerProps) => {
	const [field, meta, helper] = useField<string[]>(name)
	const formik = useFormikContext()
	const [newValue, setNewValue] = useState('')
	const [shouldDisplayError, setShouldDisplayError] = useState(false)

	useEffect(() => {
		if (formik.submitCount > 0) {
			setShouldDisplayError(true)
		}
	}, [formik.submitCount])

	const isNewValueValid = useMemo(() => {
		if (!newValue) return false
		if (field.value.includes(newValue)) return false
		return true
	}, [newValue, field])

	const removeActivity = (value: string) => {
		helper.setValue(field.value.filter(v => v !== value))
	}

	const addActivity = (value: string) => {
		if (!isNewValueValid) {
			setShouldDisplayError(true)
			if (value === '') {
				helper.setError('Czynność nie może być pusta')
			} else {
				helper.setError('Czynność jest już dodana')
			}
			return
		}
		setNewValue('')
		helper.setValue([...field.value, value])
	}

	return <Box mt={4}>
		{field.value.map(value =>
			<Tag m={0.5} key={value}>
				<TagLabel>{value}</TagLabel>
				<TagCloseButton onClick={() => removeActivity(value)} />
			</Tag>)}
		<Label>Wykonane czynności</Label>
		<FormControl isInvalid={shouldDisplayError && Boolean(meta.error)}>
			<InputGroup>
				<Input value={newValue} onChange={e => setNewValue(e.target.value)} onKeyDown={e => {
					if (e.key === 'Enter') {
						e.preventDefault()
						addActivity(newValue)
					}
				}} />
				<InputRightAddon transition='all' transitionDuration="300ms" cursor={isNewValueValid ? 'pointer' : 'not-allowed'} bgColor={isNewValueValid ? 'green.500' : 'gray.100'} color={isNewValueValid ? 'white' : 'gray.500'} fontWeight='semibold' onClick={() => addActivity(newValue)}><FaPlus /></InputRightAddon>
			</InputGroup>
			<FormErrorMessage>
				{meta.error}
			</FormErrorMessage>
		</FormControl>
	</Box>
}
