import { Box } from "@chakra-ui/react"
import { Link, useNavigate } from "react-router-dom"

type Props = {
	patientId: string
	currentTab: string
	onTabChange?: (tab: 'done' | 'toDo') => void
}

export const Tabs = ({ patientId, currentTab, onTabChange }: Props) => {
	return <Box display="flex" bgColor={'gray.100'} p={2} borderRadius={8} width='fit-content' alignItems="center" justifyContent="space-between">
		{currentTab === 'cards' ? <>
			<Tab label="Wykonane" to={`/patients/${patientId}/rehabilitations?tab=done`} />
			<Tab label="Do wykonania" to={`/patients/${patientId}/rehabilitations?tab=toDo`} />
			<Tab label="Karty" isActive={true} />
		</> : <>
			<Tab label="Wykonane" isActive={currentTab === 'done'} onClick={() => onTabChange?.('done')} />
			<Tab label="Do wykonania" isActive={currentTab === 'toDo'} onClick={() => onTabChange?.('toDo')} />
			<Tab label="Karty" to={`/patients/${patientId}/rehabilitations/cards`} />
		</>}
	</Box>
}

type TabProps = {
	isActive?: boolean
	label: string
	to?: string
	onClick?: () => void
}

const Tab = ({ isActive, to, label, onClick }: TabProps) => {
	const navigate = useNavigate()
	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			bgColor={isActive ? 'white' : 'gray.100'}
			color={isActive ? 'accent.500' : 'black'}
			py={1.5}
			px={8}
			borderRadius={8}
			cursor='pointer'
			onClick={to ? () => navigate(to) : onClick}
		>

			<Box>{label}</Box>
		</Box>
	)
}