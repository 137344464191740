import { Box, Grid, GridItem } from "@chakra-ui/react"
import { InputField, NumberField, SwitchField, TextAreaField, Tile, TileCancelButton, TileSaveButton } from "@kevea/react-components"
import { AxiosResponse } from "axios"
import { Formik, FormikProps } from "formik"
import { RehabilitationCard } from "models/rehabilitaionCard"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useOutletContext, useParams } from "react-router"
import { RehabilitationCardService } from "services/rehabilitationCard"
import { RehabilitaionCardContext } from "../RehabilitationCard"
import * as yup from 'yup'

export const GeneralEdit = () => {
	const { card, refetch, ...rest } = useOutletContext<RehabilitaionCardContext>()
	const [loading, setLoading] = useState(false)
	const formik = useRef<FormikProps<RehabilitationCard>>(null)
	const { id: patientId } = useParams<{ id: string }>()
	const navigate = useNavigate()

	useEffect(() => {
		if (card && card.status === 2) {
			navigate('..')
		}
	}, [card])


	const handleSubmit = async (values: RehabilitationCard) => {
		const afterSubmit = async (response?: AxiosResponse<RehabilitationCard>) => {
			setLoading(false)
			refetch()
			navigate(`/patients/${patientId}/rehabilitations/cards/` + (response?.data?._id ?? card._id ?? ''))
		}
		setLoading(true)
		if (card?._id) {
			await RehabilitationCardService.put(values, patientId).then(afterSubmit).catch(e => {
				setLoading(false)
			})
		} else {
			await RehabilitationCardService.post(values, patientId).then(afterSubmit).catch(e => {
				setLoading(false)
			})
		}
	}

	return <Formik onSubmit={handleSubmit} initialValues={new RehabilitationCard(card)} innerRef={formik} validationSchema={yup.object({
		cycle: yup.string().required('Cykl jest wymagany'),
		startDate: yup.string().required('Data rozpoczęcia jest wymagana'),
		numberOfSessions: yup.number().required('Liczba sesji jest wymagana').min(1, 'Liczba sesji musi być większa od 0'),

	})}>
		<Tile grid {...rest} titleElement={undefined}
			headerButtons={
				<>
					<TileSaveButton
						onSave={() => formik.current?.submitForm()}
						isLoading={loading}
					/>
					<TileCancelButton
						isLoading={loading}
						onCancel={() => navigate('..')
						}
					/>
				</>
			}>

			<GridItem colSpan={{ base: 2, lg: 1 }}>
				<InputField name="referralNumber" placeholder="Numer skierowania" label="Numer skierowania" />
				<InputField name="cycle" placeholder="Cykl" label="Cykl" />
				<NumberField name="numberOfSessions" placeholder="Liczba sesji" label="Liczba sesji" min={1} max={99} precision={0} />
			</GridItem>
			<GridItem colSpan={{ base: 2, lg: 1 }}>
				<InputField name="startDate" placeholder="Data rozpoczęcia" label="Data rozpoczęcia" type='date' />
				<NumberField name="recurrence" placeholder="Powtarzalność" label="Powtarzalność" min={1} max={99} precision={0} />
				<NumberField name="sessionTime" placeholder="Czas sesji" label="Czas sesji" min={1} max={9999} precision={0} inputRightAddon="min" />
			</GridItem>
			<GridItem colSpan={{ base: 2, lg: 1 }}>
				<TextAreaField name="mainAilments" placeholder="Główne dolegliwości" label="Główne dolegliwości" />
				<SwitchField name="metalImplant" label="Metalowy implant" />
				<SwitchField name="inflammation" label="Stan zapalny" />
				<SwitchField name="cancer" label="Nowotwory" />
				<SwitchField name="fever" label="Gorączka" />
				<SwitchField name="sensoryDisorders" label="Zaburzenia czucia" />
				<SwitchField name="pacemaker" label="Rozrusznik" />
				<TextAreaField name="diagnosis" placeholder="Rozpoznanie" label="Rozpoznanie" />
				<TextAreaField name="comments" placeholder="Uwagi" label="Uwagi" />
			</GridItem>
			<GridItem colSpan={{ base: 2, lg: 1 }}>
				<TextAreaField name="comorbidities" placeholder="Chroroby współistniejące" label="Chroroby współistniejące" />
				<SwitchField name="epilepsy" label="Padaczka" />
				<SwitchField name="heartDisease" label="Choroby serca" />
				<SwitchField name="hypertension" label="Nadciśnienie tętnicze" />
				<SwitchField name="venousProblems" label="Problemy żylne" />
				<SwitchField name="diabetes" label="Cukrzyca" />
				<Box height='59px'></Box>
				<TextAreaField name="otherDiseases" placeholder="Inne choroby" label="Inne choroby" />
				<TextAreaField name="medicalContraindications" placeholder="Opinia lekarza o przeciwskazaniach do rehabilitacji" label="Opinia lekarza o przeciwskazaniach do rehabilitacji" />
			</GridItem>
		</Tile>
	</Formik>
}