import { Box, Select, useDisclosure } from '@chakra-ui/react'
import { AlertDialog, Link, Table, Tile, TileGrid, useBreadcrumbs } from '@kevea/react-components'
import { Badge, StatusBadge } from 'components/StatusBadge'
import { Patient } from 'models/patient'
import { RehabilitationCard, TableRehabilitationCard } from 'models/rehabilitaionCard'
import patientRoutes from 'pages/patients/routes'
import { useEffect, useMemo, useState } from 'react'
import { FaLeaf } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import { Column, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { isModuleEnabled } from 'routes'
import { PatientService } from 'services/patient'
import { RehabilitationCardService } from 'services/rehabilitationCard'
import patientRehabilitationRoutes from './patientRoutes'
import { Tabs } from './views/Tabs'

export const RehabilitationCardList = () => {
	const { id: patientId } = useParams<{ id: string }>()
	const breadcrumbs = useBreadcrumbs([
		...patientRoutes,
		...patientRehabilitationRoutes,
	])
	const [selectedCards, setSelectedCards] = useState<RehabilitationCard[]>([])
	const removeDisclosure = useDisclosure()
	const [loading, setLoading] = useState(false)


	useEffect(() => {
		if (!isModuleEnabled('REHABILITATIONS')) window.location.href = '/'
	}, [])

	const { data: patient, isLoading: isLoadingPatient } = useQuery(
		'fetchPatientForRehabilitation',
		() =>
			patientId ? PatientService.get(patientId).then(res => res.data) : null,
	)
	const { data: rehabilitationCards, isLoading: isLoadingRehabilitationCards, refetch } =
		useQuery(['fetchRehabilitationCards', patientId], () =>
			patientId
				? RehabilitationCardService.getAll(patientId).then(res => res.data)
				: null,
		)

	const data = useMemo(
		() =>
			rehabilitationCards?.map(card => new TableRehabilitationCard(card)) ?? [],
		[rehabilitationCards],
	)

	const removeCards = () => {
		setLoading(true)
		const promises: Promise<any>[] = []
		for (let card of selectedCards) {
			promises.push(RehabilitationCardService.delete(card._id, card.patient))
		}
		Promise.all(promises).then(() => {
			setSelectedCards([])
			removeDisclosure.onClose()
			setLoading(false)
			refetch()
		})
	}

	const confirmCards = (cards: RehabilitationCard[]) => {
		setLoading(true)
		const promises: Promise<any>[] = []
		for (let card of cards) {
			promises.push(RehabilitationCardService.put({ ...card, status: 2 }, card.patient))
		}
		Promise.all(promises).then(() => {
			setLoading(false)
			refetch()
		})
	}

	const columns = useMemo<Column<TableRehabilitationCard>[]>(
		() => [
			{
				Header: 'Cykl',
				accessor: 'cycle',
				Cell: ({ row, value }) => <Link to={row.original.original._id}>{value}</Link>
			},
			{
				Header: 'Liczba sesji w cyklu',
				accessor: 'numberOfSessions',
			},
			{
				Header: 'Łączny czas sesji',
				accessor: 'combinedSessionTime',
			},
			{
				Header: 'Data rozpoczęcia',
				accessor: 'startDate',
			},
			{
				Header: 'Status',
				accessor: 'status',
				Cell: ({ row }) => <Badge rehabilitationCard={row.original.original} />
			},
		],
		[data],
	)

	const tableInstance = useTable(
		{ columns: columns, data: data },
		useSortBy,
		usePagination,
		useRowSelect,
	)

	return (
		<TileGrid>
			<AlertDialog
				header="Usuń Karty Rehabilitacji"
				onClose={removeDisclosure.onClose}
				isOpen={removeDisclosure.isOpen}
				onRemove={removeCards}
			>
				Czy na pewno chcesz usunąć karty rehabilitacji?
			</AlertDialog>
			<Tile
				breadcrumbs={breadcrumbs.length === 0 ? undefined : breadcrumbs}
				headerIcon={<FaLeaf />}
				header={
					patient
						? `${Patient.getFullName(patient)} - Plan rehabilitacji`
						: 'Plan rehabilitacji`'
				}
			>
				<Tabs patientId={patientId} currentTab="cards" />
				<Table tableInstance={tableInstance} loading={isLoadingRehabilitationCards || loading} buttons={[
					{
						type: 'add',
						to: 'new'
					}
				]}
					menuOptions={
						{
							singleSelection: [
								{
									label: 'Potwierdź',
									disabled: tableInstance.selectedFlatRows.some(x => x.original.status === 2),
									onClick: (rows) => {
										confirmCards(rows.map(x => x.original))
									}
								},
								{
									label: 'Usuń',
									disabled: tableInstance.selectedFlatRows.some(x => x.original.status === 2),
									onClick: (rows) => {
										setSelectedCards(rows.map(x => x.original))
										removeDisclosure.onOpen()
									}
								},
							],
							multipleSelection: [
								{
									label: 'Potwierdź',
									disabled: tableInstance.selectedFlatRows.some(x => x.original.status === 2),
									onClick: (rows) => {
										confirmCards(rows.map(x => x.original))
									}
								},
								{
									label: 'Usuń',
									disabled: tableInstance.selectedFlatRows.some(x => x.original.status === 2),
									onClick: (rows) => {
										setSelectedCards(rows.map(x => x.original))
										removeDisclosure.onOpen()
									}
								},
							]
						}
					}
				/>
			</Tile>
		</TileGrid>
	)
}
