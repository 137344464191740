import { FaClipboardList, FaFileInvoiceDollar, FaIdCard } from 'react-icons/fa'
import { RouteObject } from 'react-router-dom'
import { Patient } from './Patient'
import PatientList from './PatientList'
import { General } from './views/General'
import { GeneralEdit } from './views/GeneralEdit'
import { Measurements } from './views/Measurements'
import { PatientMedicines } from './views/Medicines'
import { TaskPlans } from './views/TaskPlans'
import { PatientTasks } from './views/Tasks'
import { PatientNotes } from './views/Notes'
import { AttachmentsWrapper } from './views/Attachments'
import { PatientMedicinesStock } from './views/MedicinesStock'
import patientBillRoutes from '../bills/patientRoutes'
import { ModuleRoute } from 'components/ModuleRoute'
import { Deposits } from './views/Deposits'
import { RestrictRoute } from 'components/RestrictRoute'
import patientRehabilitationRoutes from 'pages/rehabilitations/patientRoutes'

const patientRoutes: RouteObject[] = [
	{
		path: 'patients',
		element: (
			<RestrictRoute module="patient" power="view">
				<PatientList />
			</RestrictRoute>
		),
		breadcrumb: 'Lista Pacjentów',
	},
	{
		path: 'patients/:id',
		element: (
			<RestrictRoute module="patient" power="view">
				<Patient />
			</RestrictRoute>
		),
		children: [
			{
				index: true,
				element: <General />,
				breadcrumb: 'Kartoteka pacjenta',
				breadcrumbIcon: <FaIdCard />,
			},
			{
				path: 'edit',
				element: <GeneralEdit />,
				breadcrumb: 'Edycja kartoteki',
				breadcrumbIcon: <FaIdCard />,
			},
			{
				path: 'measurements',
				element: (
					<RestrictRoute module="patient" power="measurements">
						<Measurements />
					</RestrictRoute>
				),
				breadcrumb: 'Lista pomiarów',
				breadcrumbIcon: <FaIdCard />,
			},
			{
				path: 'medicines',
				element: (
					<RestrictRoute module="patient" power="patientMedicines">
						<PatientMedicines />
					</RestrictRoute>
				),
				breadcrumb: 'Lista leków',
				breadcrumbIcon: <FaIdCard />,
			},
			{
				path: 'medicinesstock',
				element: (
					<ModuleRoute type="MEDICINE_WAREHOUSE">
						<RestrictRoute module="patient" power="warehouse">
							<PatientMedicinesStock />
						</RestrictRoute>
					</ModuleRoute>
				),
				breadcrumb: 'Magazyn leków',
				breadcrumbIcon: <FaIdCard />,
			},
			{
				path: 'notes',
				element: (
					<RestrictRoute module="patient" power="notes">
						<PatientNotes />
					</RestrictRoute>
				),
				breadcrumb: 'Lista notatek',
				breadcrumbIcon: <FaIdCard />,
			},
			{
				path: 'deposits',
				element: (
					<RestrictRoute module="patient" power="deposits">
						<Deposits />
					</RestrictRoute>
				),
				breadcrumb: 'Depozyty',
				breadcrumbIcon: <FaClipboardList />,
			},
			{
				path: 'taskplans',
				element: (
					<RestrictRoute module="patient" power="taskPlans">
						<TaskPlans />
					</RestrictRoute>
				),
				breadcrumb: 'Harmonogram zadań',
				breadcrumbIcon: <FaIdCard />,
			},
			{
				path: 'tasks',
				element: (
					<RestrictRoute module="patient" power="tasks">
						<PatientTasks />
					</RestrictRoute>
				),
				breadcrumb: 'Zadania',
				breadcrumbIcon: <FaIdCard />,
			},
			{
				path: 'attachments',
				element: <AttachmentsWrapper />,
				breadcrumb: 'Lista załączników',
				breadcrumbIcon: <FaIdCard />,
			},
			...patientBillRoutes,
			...patientRehabilitationRoutes
		],
	},
	{
		path: 'patients/new',
		element: <Patient />,
		children: [
			{
				index: true,
				element: <GeneralEdit />,
				breadcrumb: 'Nowa kartoteka',
				breadcrumbIcon: <FaIdCard />,
			},
		],
	},
]

export default patientRoutes
