import { Box, Tag, Text, useDisclosure } from "@chakra-ui/react"
import { Link, Table, Tile, TileGrid, useBreadcrumbs } from "@kevea/react-components"
import { Badge } from "components/StatusBadge"
import { Patient } from "models/patient"
import { RehabilitationSession, TableRehabilitationSession } from "models/rehabilitationSession"
import patientRoutes from "pages/patients/routes"
import { useEffect, useMemo, useState } from "react"
import { FaLeaf } from "react-icons/fa"
import { useQuery } from "react-query"
import { useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import { Column, useTable, useSortBy, usePagination, useRowSelect, useFilters } from "react-table"
import { isModuleEnabled } from "routes"
import { PatientService } from "services/patient"
import { RehabilitationSessionsService } from "services/rehabilitationSession"
import patientRehabilitationRoutes from "./patientRoutes"
import { CompleteSessionModal } from "./views/CompleteSessionModal"
import { Tabs } from "./views/Tabs"

export const RehabilitationSessionList = () => {
	const { id: patientId } = useParams<{ id: string }>()
	const [searchParams] = useSearchParams()
	const breadcrumbs = useBreadcrumbs([...patientRoutes, ...patientRehabilitationRoutes])
	const [currentTab, setCurrentTab] = useState<string>(searchParams.get('tab') ?? 'done')
	const sessionPreviewDisclosure = useDisclosure()
	const [selectedSession, setSelectedSession] = useState<RehabilitationSession | undefined>()
	const [loading, setLoading] = useState(false)


	useEffect(() => {
		if (!isModuleEnabled('REHABILITATIONS')) window.location.href = '/'
	}, [])

	const { data: patient, isLoading: isLoadingPatient } = useQuery(
		'fetchPatientForSessions',
		() =>
			patientId ? PatientService.get(patientId).then(res => res.data) : null,
	)

	useEffect(() => {
		tableInstance.setFilter('status', currentTab === 'done' ? 2 : 1)
		tableInstance.setHiddenColumns(currentTab === 'toDo' ? ['activities', 'complitionTime'] : [])
	}, [currentTab])

	const { data: rehabilitationSessions, isLoading: isLoadingRehabilitationSessions, refetch } =
		useQuery(['fetchRehabilitationSessions', patientId], () =>
			patientId
				? RehabilitationSessionsService.getAll(patientId).then(res => res.data)
				: null,
		)

	const data = useMemo(
		() => rehabilitationSessions?.map(session => new TableRehabilitationSession(session)) ?? [],
		[rehabilitationSessions],
	)

	const handleSessionOpen = (session: RehabilitationSession) => {
		setSelectedSession(session)
		sessionPreviewDisclosure.onOpen()
	}

	const handleSessionClose = (session?: RehabilitationSession) => {
		sessionPreviewDisclosure.onClose()
		setSelectedSession(undefined)
		if (session) {
			setLoading(true)
			RehabilitationSessionsService.put(session, patientId).then(res => {
				refetch()
				setLoading(false)
			})
		}
	}

	const columns = useMemo<Column<TableRehabilitationSession>[]>(
		() => [
			{
				Header: 'Sesja',
				accessor: 'sessionNumber',
				Cell: ({ row, value }) => row.original.original.status === 1 ? <Link onClick={() => handleSessionOpen(row.original.original)}><Text>{value} z {row.original.numberOfSessions}</Text></Link> : <Text>{value} z {row.original.numberOfSessions}</Text>
			},
			{
				Header: 'Cykl',
				accessor: 'cycle',
			},
			{
				Header: 'Godzina wykonania',
				accessor: 'complitionTime',
			},
			{
				Header: 'Czas sesji',
				accessor: 'sessionTime',
				Cell: ({ value }) => <Text>{value} min</Text>
			},
			{
				Header: 'Data',
				accessor: 'date',
			},
			{
				Header: 'Wykonane czynności',
				accessor: 'activities',
				Cell: ({ value }) => value.map(v => <Tag key={v} m={0.5}>{v}</Tag>)
			},
			{
				Header: 'Notatka',
				accessor: 'note'
			},
			{
				Header: 'Status',
				accessor: 'status',
				Cell: ({ row }) => <Badge rehabilitationSession={row.original.original} />
			},
		],
		[],
	)

	const tableInstance = useTable(
		{
			columns: columns,
			data,
			initialState: {
				filters: [{ id: 'status', value: currentTab === 'done' ? 2 : 1 }],
			}
		},
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	return (
		<TileGrid>
			<Tile breadcrumbs={breadcrumbs.length === 0 ? undefined : breadcrumbs} headerIcon={<FaLeaf />} header={
				patient
					? `${Patient.getFullName(patient)} - Plan rehabilitacji`
					: 'Plan rehabilitacji`'
			}>
				<Tabs patientId={patientId} currentTab={currentTab} onTabChange={setCurrentTab} />
				{selectedSession &&
					<CompleteSessionModal isOpen={sessionPreviewDisclosure.isOpen} onClose={handleSessionClose} session={selectedSession} />}
				<Table tableInstance={tableInstance} buttons={[]} loading={loading || isLoadingRehabilitationSessions} />
			</Tile>
		</TileGrid>
	)
}